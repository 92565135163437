import { Navigate, useRoutes } from 'react-router-dom'
import React, { Suspense, lazy } from 'react'

// import Home from '@pages/home/Home'
import About from '@pages/about'
import Partners from '@pages/partners'
import Brands from '@pages/brands'
import SuccesStoryPage from '@pages/sucess-story'
import BlogPage from '@pages/blog'
import NewsPage from '@pages/news'
import EventPage from '@pages/event'
import ProductExplainer from '@pages/product-explainer'
import Podcast from '@pages/podcast'
import Page404 from '@pages/page404'
import APIDocs from '@pages/api-docs'
import APIDocsSample from '@pages/api-docs-new'
import APIDocDetails from '@pages/api-doc-details'
import Contact from '@pages/contact'
import BlogPageDetails from '@pages/blog/details'
import Career from '@pages/career'
import SuccessStoryPageDetails from '@pages/sucess-story/details'

import GrowthFinancing from '@pages/product/growth-financing'
import KwikChat from '@pages/product/kwikchat'
import KwikCheckout from '@pages/product/kwik-checkout'
import KwikAdvantage from '@pages/product/kwik-advantage'
import PrivacyPolicy from '@pages/privacy-policy'
import CheckoutPrivacyPolicy from '@pages/privacy-policy/checkout-privacy-policy'
import InvestorPage from '@pages/investors'
import ShopifyPage from '@pages/product/shopify'
import TermsPage from '@pages/terms'
import SharkTank from '@pages/shark-tank'
import CookiesPage from '@pages/cookie-policy'
import GrievancePage from '@pages/grievance-policy'
import { PageLoader } from '@components/lazyimage'
import PlaybookPage from '@pages/playbook'
import PlaybookPageDetails from '@pages/playbook/details'
import KwikCheckoutPage from '@pages/kwik-checkout'
import OneClickCheckout from '@pages/one-click-checkout'
import KwikCheckOutCOD from '@pages/kwik-checkout-cod'
import KwikCheckOutApp from '@pages/kwik-checkout-app'
import KwikGlory from '@pages/growth-champions-disruptors'


const Home = lazy(() => import('@pages/home/Home'))
/*const About = lazy(() => import('@pages/about'))
const Partners = lazy(() => import('@pages/partners'))
const Brands = lazy(() => import('@pages/brands'))
const SuccesStoryPage = lazy(() => import('@pages/sucess-story'))
const BlogPage = lazy(() => import('@pages/blog'))
const NewsPage = lazy(() => import('@pages/news'))
const EventPage = lazy(() => import('@pages/event'))
const ProductExplainer = lazy(() => import('@pages/product-explainer'))
const Podcast = lazy(() => import('@pages/podcast'))
const Page404 = lazy(() => import('@pages/page404'))
const APIDocs = lazy(() => import('@pages/api-docs'))
const APIDocDetails = lazy(() => import('@pages/api-doc-details'))
const Contact = lazy(() => import('@pages/contact'))
const BlogPageDetails = lazy(() => import('@pages/blog/details'))
const Career = lazy(() => import('@pages/career'))
const SuccessStoryPageDetails = lazy(() => import('@pages/sucess-story/details'))

const GrowthFinancing = lazy(() => import('@pages/product/growth-financing'))
const KwikChat = lazy(() => import('@pages/product/kwikchat'))
const KwikCheckout = lazy(() => import('@pages/product/kwik-checkout'))
const KwikAdvantage = lazy(() => import('@pages/product/kwik-advantage'))
const PrivacyPolicy = lazy(() => import('@pages/privacy-policy'))
const InvestorPage = lazy(() => import('@pages/investors'))
const ShopifyPage = lazy(() => import('@pages/product/shopify'))
const TermsPage = lazy(() => import('@pages/terms'))
const CookiesPage = lazy(() => import('@pages/cookie-policy'))
const GrievancePage = lazy(() => import('@pages/grievance-policy'))*/

const Router = () => {
	//const Event = lazy(() => import("@pages/event"))
	const routes = useRoutes([
		{ path: '', element: <Home /> },
		{ path: '/', element: <Home /> },
		{ path: '/kwikcheckoutapp', element: <KwikCheckOutApp /> },
		{ path: '/kwikcodapp', element: <KwikCheckOutCOD /> },
		{ path: '/hall-of-glory', element: <KwikGlory /> },
		{ path: '/about', element: <About /> },
		{ path: '/careers', element: <Career /> },
		{ path: '/partners', element: <Partners /> },
		{ path: '/brands', element: <Brands /> },
		{ path: '/success-story', element: <SuccesStoryPage /> },
		{ path: '/success-story/:slug', element: <SuccessStoryPageDetails /> },

		{ path: '/kwikcheckoutapp/T&C', element: <CheckoutPrivacyPolicy /> },

		{ path: '/news', element: <NewsPage /> },
		{ path: '/event', element: <EventPage /> },
		{ path: '/privacy-policy', element: <PrivacyPolicy /> },
		{ path: '/data-policy', element: <PrivacyPolicy /> },
		{ path: '/investors', element: <InvestorPage /> }, // static

		{ path: '/product-explainer', element: <ProductExplainer /> },
		{ path: '/podcast', element: <Podcast /> },
		{ path: '/api-docs', element: <APIDocs /> },
		{ path: '/api-doc-integration', element: <APIDocsSample /> },
		{ path: '/api-docs/:slug', element: <APIDocDetails /> },
		{ path: '/api-integration', element: <APIDocDetails /> },
		{ path: '/contact', element: <Contact />, handle: { bg: "light" } },
		{ path: '/blog', element: <BlogPage /> },
		{ path: '/blog/:slug', element: <BlogPageDetails /> },

		{ path: '/product/growth-financing', element: <GrowthFinancing /> },
		// { path: '/kwikchat', element: <KwikChat /> },
		{ path: '/kwikengage', element: <KwikChat /> },
		{ path: '/kwikchat', element: <Navigate to="/kwikengage" replace /> },
		{ path: '/product/kwikchat', element: <Navigate to="/product/kwikengage" replace /> },
		
		{ path: '/product/kwikengage', element: <KwikChat /> },
		{ path: '/product/kwikcheckout', element: <KwikCheckout /> },

		{ path: '/product/smart-cod-suite', element: <KwikAdvantage /> },
		{ path: '/product/shopify', element: <ShopifyPage /> }, // static

		// { path: '/shark-tank-terms', element: <SharkTank /> },
		{ path: '/terms', element: <TermsPage /> },
		{ path: '/cookie-policy', element: <CookiesPage /> },

		{ path: '/kwikcheckout', element: <KwikCheckoutPage /> },

		// { path: '/grievance-policy', element: <GrievancePage /> },
		{ path: '/oneclickcheckout', element: <OneClickCheckout /> },

		{ path: '/playbook', element: <PlaybookPage /> },
		{ path: '/playbook/:slug', element: <PlaybookPageDetails /> },
		// { path: '/blog/:slug', element: <BlogPageDetails /> },
		{ path: '/grievance-policy', element: <GrievancePage /> }, // static


		{ path: '*', element: <Page404 /> },
	])

	// return routes
	return (
		<Suspense fallback={<PageLoader />}>
			{routes}
		</Suspense>
	);
}
export default Router